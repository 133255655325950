import { isEmptyNums, isEqualVariances, isErrorCI, isInvalidNums, isNegative, isNegativeCustom, isNotPosWholeNumberSS, isNotWholeNumber, isNotWholeNumberCustom, isValidN } from '@/js/lib/input-check.js'
import _ from 'lodash'
var jStat = require('jstat').jStat

const calculateCI = (data) => {
    var { allData } = data
    const compute = allData.formula.name
    var output

    if (compute === 'OneZ') {
        output = oneMeanLarge(data)
    } else if (compute === 'OneT') {
        output = oneMeanSmall(data)
    } else if (compute === 'OneP') {
        output = oneProportion(data)
    } else if (compute === 'OneV') {
        output = oneVariance(data)
    } else if (compute === 'TwoV') {
        output = twoVariances(data)
    } else if (compute === 'TwoDLarge') {
        output = twoDLarge(data)
    } else if (compute === 'TwoDSmall') {
        output = twoDSmall(data)
    } else if (compute === 'TwoILarge') {
        output = twoILarge(data)
    } else if (compute === 'TwoISmallEqual') {
        output = twoISmallEqual(data)
    } else if (compute === 'TwoISmallNotEqual') {
        output = twoISmallNotEqual(data)
    } else if (compute === 'TwoP') {
        output = twoProportion(data)
    } else {
        console.log('No matching formula')
    }
    return output
}

const oneMeanLarge = (data) => {
    var { n, s, x̄, cl } = data
    var output = {}
    _.set(output, 'input', { n, s, x̄, cl })

    var Parsedx̄ = Number(x̄)
    var Parsedn = Number(n)
    var Parseds = Number(s)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x̄, n, s, cl)) { return }
    if (isInvalidNums(Parsedx̄, Parsedn, Parseds, ParsedDisplaycL)) { return }
    if (isErrorCI(cl)) { return }
    if (isNegative(Parseds)) { return }
    if (isValidN(Parsedn)) { return }
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }

    var tempDisplaycL = ParsedDisplaycL
    var temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = Number(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var X = jStat.normal.inv(newcL, 0, 1)
    var ZFixed = X.toFixed(2)
    temp = Parseds / Math.sqrt(Parsedn)
    var temp1 = temp * ZFixed
    var Parsedtemp1 = parseFloat(temp1)

    var upperEnd = Parsedx̄ + Parsedtemp1
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = Parsedx̄ - Parsedtemp1
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        z: ZFixed,
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const oneMeanSmall = (data) => {
    var { n, s, x̄, cl } = data
    var output = {}
    _.set(output, 'input', { n, s, x̄, cl })

    var Parsedx̄ = Number(x̄)
    var Parsedn = Number(n)
    var Parseds = Number(s)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x̄, n, s, cl)) { return }
    if (isInvalidNums(Parsedx̄, Parsedn, Parseds, ParsedDisplaycL)) { return }
    if (isErrorCI(cl)) { return }
    if (isNegative(Parseds)) { return }
    if (isNotPosWholeNumberSS(Parsedn)) { return }
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }

    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    var temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = parseFloat(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var CalculatedDf = Parsedn - 1
    var tDf = jStat.studentt.inv(newcL, CalculatedDf)
    var tDfFixed = tDf.toFixed(2)
    temp = Parseds / Math.sqrt(Parsedn)
    var temp1 = temp * tDfFixed
    var Parsedtemp1 = parseFloat(temp1)

    var upperEnd = Parsedx̄ + Parsedtemp1
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = Parsedx̄ - Parsedtemp1
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        t: tDfFixed,
        df: CalculatedDf,
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const oneProportion = (data) => {
    var { n, x, cl } = data
    var output = {}
    _.set(output, 'input', { n, x, cl })

    var Parsedx = Number(x)
    var Parsedn = Number(n)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x, n, cl)) { return }
    if (isInvalidNums(Parsedx, Parsedn, ParsedDisplaycL)) { return }
    if (isErrorCI(cl)) { return }
    if (Parsedn < 0 || Parsedx <= 0 || (isNotWholeNumberCustom(Parsedn, Parsedx))) {
        alert('Inputs for x and n must be positive whole numbers')
        return
    }
    if (Parsedx > Parsedn) {
        alert('n must be larger than x')
    }
    if (!(x >= 5 && n - x >= 5)) {
        alert('Must have x >=5 and n-x>=5')
        return
    }
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }

    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    let temp = (1.0 - tempDisplaycL) / 2.0
    let Parsedtemp = parseFloat(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    const calculatedp̂ = Parsedx / Parsedn
    var calculatedZ = jStat.normal.inv(newcL, 0, 1)
    var Parsedp̂ = Number(calculatedp̂)
    var ParsedZ = Number(calculatedZ)
    temp = ParsedZ * Math.sqrt((Parsedp̂ * (1.0 - Parsedp̂)) / Parsedn)
    Parsedtemp = parseFloat(temp)

    var upperEnd = Parsedp̂ + Parsedtemp
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = Parsedp̂ - Parsedtemp
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        z: calculatedZ.toFixed(2),
        p̂: calculatedp̂.toFixed(3),
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const oneVariance = (data) => {
    var { n, s, cl } = data

    var output = {}
    _.set(output, 'input', { n, s, cl })

    var Parseds = Number(s)
    var Parsedn = Number(n)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(n, s, cl)) { return }
    if (isInvalidNums(Parseds, Parsedn, ParsedDisplaycL)) { return }
    if (isErrorCI(ParsedDisplaycL)) { return }
    if (isNotWholeNumber(Parsedn)) { return }
    if (isNegative(Parseds)) { return }

    // automatically changes input ParsedDisplaycL to number (if not already)
    if (ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 0.99) {
        ParsedDisplaycL = ParsedDisplaycL * 100
    }
    var tempDisplaycL = ParsedDisplaycL

    //= =======

    var lower = (1 - tempDisplaycL * 0.01) / 2 // <-- this is the probability in the left tail
    var upper = 1 - lower

    var df = Parsedn - 1

    var chiSqLower = jStat.chisquare.inv(lower, df)
    var chiSqLower2 = Number(chiSqLower.toFixed(2)) // rounded to 2 decimal places
    var chiSqUpper = jStat.chisquare.inv(upper, df)
    var chiSqUpper2 = Number(chiSqUpper.toFixed(2)) // rounded to 2 decimal places

    // Then compute the left and right sides of the formula for 𝜎2
    var lowerVal = ((Parsedn - 1) * Parseds * Parseds) / chiSqUpper2
    var lowerVal3 = lowerVal.toFixed(3)
    var upperVal = ((Parsedn - 1) * Parseds * Parseds) / chiSqLower2
    var upperVal3 = upperVal.toFixed(3)

    // console.log({ tempDisplaycL, lower, upper, df })
    // console.log({ chiSqLower, chiSqLower2, chiSqUpper, chiSqUpper2, lowerVal, lowerVal3, upperVal, upperVal3 })

    _.set(output, 'data', {
        chiLower: chiSqLower2,
        chiUpper: chiSqUpper2,
        ci: { lowerEnd: lowerVal3, upperEnd: upperVal3 },
        clDisplay: cl
    })
    return output
}

const twoVariances = (data) => {
    var { s1, s2, n1, n2, cl } = data

    var output = {}
    _.set(output, 'input', { s1, s2, n1, n2, cl })

    var Parseds1 = Number(s1)
    var Parseds2 = Number(s2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(s1, s2, n1, n2, ParsedDisplaycL)) { return }
    if (isInvalidNums(Parseds1, Parseds2, Parsedn1, Parsedn2, ParsedDisplaycL)) { return }
    if (isErrorCI(ParsedDisplaycL)) { return }
    if (isNegative(Parseds1, Parseds2)) { return }
    if (isNotWholeNumber(Parsedn1, Parsedn2)) { return }

    // automatically changes input ParsedDisplaycL to number (if not already)
    if (ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 0.99) {
        ParsedDisplaycL = ParsedDisplaycL * 100
    }
    var tempDisplaycL = ParsedDisplaycL

    //= =======

    var lower = (1 - tempDisplaycL * 0.01) / 2 // <-- this is the probability in the left tail
    var upper = 1 - lower
    var df1 = Parsedn1 - 1
    var df2 = Parsedn2 - 1

    // Note that df1 and df2 have switched positions in both of the following
    var fLower = jStat.centralF.inv(lower, df2, df1)
    var fLower2 = Number(fLower.toFixed(2)) // rounded to 2 decimal places
    var fUpper = jStat.centralF.inv(upper, df2, df1)
    var fUpper2 = Number(fUpper.toFixed(2)) // rounded to 2 decimal places

    // Then compute the left and right sides of the formula for ratio of variances
    var lowerVal = fLower2 * Math.pow(Parseds1, 2) / Math.pow(Parseds2, 2)
    var lowerVal3 = lowerVal.toFixed(3) // rounded to 3 decimal places
    var upperVal = fUpper2 * Math.pow(Parseds1, 2) / Math.pow(Parseds2, 2)
    var upperVal3 = upperVal.toFixed(3) // rounded to 3 decimal places

    // console.log({ tempDisplaycL, lower, upper, df1, df2 })
    // console.log({ fLower, fLower2, fUpper, fUpper2, lowerVal, lowerVal3, upperVal, upperVal3 })

    _.set(output, 'data', {
        chiLower: fLower2,
        chiUpper: fUpper2,
        ci: { lowerEnd: lowerVal3, upperEnd: upperVal3 },
        clDisplay: cl
    })
    return output
}

const twoDLarge = (data) => {
    var { n, sd, d, cl } = data
    var output = {}
    _.set(output, 'input', { n, sd, d, cl })

    var Parsedd = Number(d)
    var Parsedn = Number(n)
    var Parsedsd = Number(sd)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(d, n, sd, cl)) { return }
    if (isInvalidNums(Parsedd, Parsedn, Parsedsd, ParsedDisplaycL)) { return }
    if (isValidN(Parsedn)) { return }
    if (isErrorCI(cl)) { return }
    if (isNegative(Parsedsd)) { return }

    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }

    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    const temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = parseFloat(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var X = jStat.normal.inv(newcL, 0, 1)
    const ZFixed = X.toFixed(2)

    var offset = ZFixed * Parsedsd / (Math.sqrt(Parsedn))
    var upperEnd = Parsedd + offset
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = Parsedd - offset
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        z: ZFixed,
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const twoDSmall = (data) => {
    var { n, sd, d, cl } = data
    var output = {}
    _.set(output, 'input', { n, sd, d, cl })

    var Parsedd = Number(d)
    var Parsedn = Number(n)
    var Parsedsd = Number(sd)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(d, n, sd, cl)) { return }
    if (isInvalidNums(Parsedd, Parsedn, Parsedsd, ParsedDisplaycL)) { return }
    if (isNotPosWholeNumberSS(Parsedn)) { return }
    if (isErrorCI(cl)) { return }
    if (Parsedn < 2 || Parsedn > 29) {
        alert('Sample Size must be smaller than 30')
        return
    }
    if (isNegative(Parsedsd)) { return }

    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }
    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    let temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = parseFloat(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    const CalculatedDf = Parsedn - 1
    var tDf = jStat.studentt.inv(newcL, CalculatedDf)
    const tDfFixed = tDf.toFixed(2)
    temp = Parsedsd / Math.sqrt(Parsedn)
    var temp1 = temp * tDfFixed
    var Parsedtemp1 = parseFloat(temp1)

    var upperEnd = Parsedd + Parsedtemp1
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = Parsedd - Parsedtemp1
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        t: tDfFixed,
        df: CalculatedDf,
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const twoILarge = (data) => {
    var { x̄1, x̄2, n1, n2, s1, s2, cl } = data
    var output = {}
    _.set(output, 'input', { x̄1, x̄2, n1, n2, s1, s2, cl })

    var Parsedx̄1 = Number(x̄1)
    var Parsedx̄2 = Number(x̄2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)
    var Parseds1 = Number(s1)
    var Parseds2 = Number(s2)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x̄1, x̄2, n1, n2, s1, s2, cl)) { return }
    if (isInvalidNums(Parsedx̄1, Parsedx̄2, Parsedn1, Parsedn2, Parseds1, Parseds2, ParsedDisplaycL)) { return }
    if (isNotPosWholeNumberSS(Parsedn1, Parsedn2)) { return }
    if (isErrorCI(cl)) { return }
    if (isNegative(Parseds1, Parseds2)) { return }
    if (Parsedn1 < 30 || Parsedn2 < 30) {
        alert('n1 & n2 must be ≥ 30')
        return
    }

    // automatically changes input cl to percent (if not already)
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }
    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    var temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = Number(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var calculatedZ = jStat.normal.inv(newcL, 0, 1)
    const calculatedZFixed = calculatedZ.toFixed(2)
    var sum = (Math.pow(Parseds1, 2) / Parsedn1) + (Math.pow(Parseds2, 2) / Parsedn2)

    var root = Math.sqrt(sum)
    root = parseFloat(root)
    var diff = Parsedx̄1 - Parsedx̄2
    var upperEnd = diff + (calculatedZFixed * root)
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = diff - (calculatedZFixed * root)
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        z: calculatedZFixed,
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const twoISmallEqual = (data) => {
    var { x̄1, x̄2, n1, n2, s1, s2, cl } = data
    var output = {}
    _.set(output, 'input', { x̄1, x̄2, n1, n2, s1, s2, cl })

    var Parsedx̄1 = Number(x̄1)
    var Parsedx̄2 = Number(x̄2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)
    var Parseds1 = Number(s1)
    var Parseds2 = Number(s2)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x̄1, x̄2, n1, n2, s1, s2, cl)) { return }
    if (isInvalidNums(Parsedx̄1, Parsedx̄2, Parsedn1, Parsedn2, Parseds1, Parseds2, ParsedDisplaycL)) { return }
    if (isErrorCI(cl)) { return }
    if (isNegative(Parseds1, Parseds2)) { return }
    if (isNotWholeNumber(Parsedn1, Parsedn2)) { return }
    if (Parsedn1 >= 30 && Parsedn2 >= 30) {
        alert('n1 or n2 must be < 30')
        return
    }
    if (!isEqualVariances(Parsedn1, Parsedn2, Parseds1, Parseds2)) { return }

    // automatically changes input cl to percent (if not already)
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }
    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    var temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = Number(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var CalculatedDf = Parsedn1 + Parsedn2 - 2
    var CalculatedSp2 = ((Parsedn1 - 1) * Math.pow(Parseds1, 2) / CalculatedDf) + ((Parsedn2 - 1) * Math.pow(Parseds2, 2) / CalculatedDf)
    var tDf = jStat.studentt.inv(newcL, CalculatedDf)
    var TdfFixed = tDf.toFixed(2)

    var root = TdfFixed * Math.sqrt(CalculatedSp2 * (1 / Parsedn1 + 1 / Parsedn2))
    root = parseFloat(root)
    var diff = Parsedx̄1 - Parsedx̄2
    var lowerEnd = diff - root
    lowerEnd = lowerEnd.toFixed(3)
    var upperEnd = diff + root
    upperEnd = upperEnd.toFixed(3)

    _.set(output, 'data', {
        t: TdfFixed,
        df: CalculatedDf,
        sp2: CalculatedSp2.toFixed(4),
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const twoISmallNotEqual = (data) => {
    var { x̄1, x̄2, n1, n2, s1, s2, cl } = data
    var output = {}
    _.set(output, 'input', { x̄1, x̄2, n1, n2, s1, s2, cl })

    var Parsedx̄1 = Number(x̄1)
    var Parsedx̄2 = Number(x̄2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)
    var Parseds1 = Number(s1)
    var Parseds2 = Number(s2)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x̄1, x̄2, n1, n2, s1, s2, cl)) { return }
    if (isInvalidNums(Parsedx̄1, Parsedx̄2, Parsedn1, Parsedn2, Parseds1, Parseds2, ParsedDisplaycL)) { return }
    if (isErrorCI(cl)) { return }
    if (isNegative(Parseds1, Parseds2)) { return }
    if (isNotWholeNumber(Parsedn1, Parsedn2)) { return }
    if (Parsedn1 >= 30 && Parsedn2 >= 30) {
        alert('n1 or n2 must be < 30')
        return
    }
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }

    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    var temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = Number(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var df = 0
    if (Parsedn1 === Parsedn2) { // EQUAL TO
        df = Parsedn1 + Parsedn2 - 2
    } else {
        const top = Math.pow((Math.pow(Parseds1, 2) / Parsedn1) + (Math.pow(Parseds2, 2) / Parsedn2), 2)
        const tempBot1 = Math.pow(Parseds1, 2) / Parsedn1
        const bot1 = Math.pow(tempBot1, 2) / (Parsedn1 - 1)

        const tempBot2 = Math.pow(Parseds2, 2) / Parsedn2
        const bot2 = Math.pow(tempBot2, 2) / (Parsedn2 - 1)

        df = top / (bot1 + bot2)
    }
    var CalculatedDf = Math.round(df)
    var tDf = jStat.studentt.inv(newcL, CalculatedDf)
    var tDff = tDf.toFixed(2)

    var sum = (Math.pow(Parseds1, 2) / Parsedn1) + (Math.pow(Parseds2, 2) / Parsedn2)
    var root = Math.sqrt(sum)
    root = parseFloat(root)
    var diff = Parsedx̄1 - Parsedx̄2
    var upperEnd = diff + (tDff * root)
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = diff - (tDff * root)
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        t: tDff,
        df: CalculatedDf,
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

const twoProportion = (data) => {
    var { n1, n2, x1, x2, cl } = data
    var output = {}
    _.set(output, 'input', { n1, n2, x1, x2, cl })

    var Parsedx1 = Number(x1)
    var Parsedx2 = Number(x2)
    var Parsedn1 = Number(n1)
    var Parsedn2 = Number(n2)
    var ParsedDisplaycL = Number(cl)

    if (isEmptyNums(x1, x2, n1, n2, cl)) { return }
    if (isInvalidNums(Parsedx1, Parsedx2, Parsedn1, Parsedn2, ParsedDisplaycL)) { return }
    if (isErrorCI(cl)) { return }
    if ((isNegativeCustom(Parsedx1, Parsedx2, Parsedn1, Parsedn2)) ||
          (isNotWholeNumberCustom(Parsedx1, Parsedx2, Parsedn1, Parsedn2))) {
        alert('Inputs for x and n must be positive whole numbers')
        return
    }
    if (!((Parsedx1 >= 5) && (Parsedn1 - Parsedx1 >= 5) && (Parsedx2 >= 5) && (Parsedn2 - Parsedx2 >= 5))) {
        alert('Error. Must have: x₁ ≥ 5 and n₁ - x₁ ≥ 5 and x₂ ≥ 5 and n₂ - x₂ > 5')
        return
    }
    const calculatedp̂1 = x1 / n1
    const calculatedp̂2 = x2 / n2
    if (!(ParsedDisplaycL >= 0.0 && ParsedDisplaycL <= 1.0)) {
        ParsedDisplaycL = ParsedDisplaycL / 100
    }
    var tempDisplaycL = ParsedDisplaycL
    // converts cL to account for outside bounds
    var temp = (1.0 - tempDisplaycL) / 2.0
    var Parsedtemp = Number(temp)
    var newcL = Parsedtemp + tempDisplaycL // newcL used for calculation
    var calculatedZ = jStat.normal.inv(newcL, 0, 1)
    var calculatedZFixed = calculatedZ.toFixed(2)

    var root = calculatedZFixed * Math.sqrt((calculatedp̂1 * (1.0 - calculatedp̂1) / Parsedn1) + (calculatedp̂2 * (1 - calculatedp̂2) / Parsedn2))
    root = parseFloat(root)
    var upperEnd = (calculatedp̂1 - calculatedp̂2) + root
    upperEnd = upperEnd.toFixed(3)
    var lowerEnd = (calculatedp̂1 - calculatedp̂2) - root
    lowerEnd = lowerEnd.toFixed(3)

    _.set(output, 'data', {
        z: calculatedZFixed,
        p̂1: calculatedp̂1.toFixed(3),
        p̂2: calculatedp̂2.toFixed(3),
        ci: { upperEnd, lowerEnd },
        clDisplay: cl
    })
    return output
}

export default {
    calculateCI
}
